import { Icon } from 'components'
import styles from '../index.module.css'

const HomeHeaderBlock = () => {
  return (
    <div className={styles.header}>
      <div className={styles.title}>
        <Icon icon="logo_β" className={styles.logo_icon} />
      </div>
      <div className={styles.header_text}>
        あなたは会議の参加者を
        <br />
        思い出せなかったことはありませんか？
        <br />
        会議ログは「あの時会議参加していたの誰だっけ？」と
        <br />
        悩むあなたを手助けします。
      </div>
      <div className={styles.text_sign_up}>
        初回ご利用時は新規登録をお願いします。
      </div>
    </div>
  )
}

export default HomeHeaderBlock
