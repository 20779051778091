import { FC } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { Loading } from 'components'
import { useAuthStateLoadable } from 'globalStates/authState'

export type AuthRouterProps = {}

const AuthRouter: FC<AuthRouterProps> = () => {
  const location = useLocation()
  const { contents, state } = useAuthStateLoadable()

  if (state !== 'loading' && contents.login === false) {
    return <Navigate to="/" state={location.pathname} />
  }

  switch (state) {
    case 'hasValue':
      return (
        <>
          <Outlet />
        </>
      )
    case 'loading':
      return <Loading />
    case 'hasError':
      throw contents
  }
}

export default AuthRouter
