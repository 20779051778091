import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from "react-router-dom"
import classnames from 'classnames'
import { Button } from 'components'
import { Icon } from 'components'
import styles from '../index.module.css'
// import HomeSnsBlock from './HomeSnsBlock'

export interface SigninInputType {
  email: string
  password: string
}

export type HomeSigninBlockProps = {
  onClickChange: () => void
  onClickSignin: (data: SigninInputType) => void
  errorMessage: string | null
}

/** ログイン */
const HomeSigninInputBlock: FC<HomeSigninBlockProps> = ({
  onClickChange,
  onClickSignin,
  errorMessage,
}) => {
  const {
    register,
    handleSubmit,
    //watch,
    formState: { errors },
  } = useForm<SigninInputType>()

  return (
    <div className="sign-in">
      <form onSubmit={handleSubmit(onClickSignin)}>
        <div className={styles.relative}>
          <Icon
            icon="mail"
            iconClassName={styles.icon}
            className={styles.input_icon}
          ></Icon>
          <input
            className={classnames(styles.input, styles.input_sign_in)}
            placeholder="メールアドレス"
            {...register('email', {
              required: 'メールアドレスを入力してください。',
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/,
                message: '正しいメールアドレスを入力してください。',
              },
            })}
          />
        </div>
        {errors.email && (
          <div className={styles.error}>{errors.email?.message}</div>
        )}

        <div className={styles.relative}>
          <Icon
            icon="pass"
            iconClassName={styles.icon}
            className={styles.input_icon}
          ></Icon>
          <input
            className={classnames(styles.input, styles.input_sign_in)}
            type="password"
            placeholder="パスワード"
            {...register('password', {
              required: 'パスワードを入力してください。',
              pattern: {
                value: /^([a-zA-Z0-9!"#$%&()*+,-./:;<=>?@^_`{|}~]{8,50})$/,
                message: '8文字以上50文字以下の半角英数字、半角記号（!"#$%&()*+,-./:;<=>?@^_`{|}~）を入力してください。',
              },
            })}
          />
        </div>
        {errors.password && (
          <div className={styles.error}>{errors.password?.message}</div>
        )}

        {errorMessage && <div className={styles.error}>{errorMessage}</div>}
        <Button type="submit">ログイン</Button>
      </form>
      {/* <HomeSnsBlock /> */}
      {/* <button onClick={onClickSignin}>ログイン</button> */}
      <Button color="secondary" onClick={onClickChange}>
        新規登録はこちら
      </Button>
      <p className={styles.reset_password}>
        パスワードを忘れた方は
        <Link to="/reset-password" className={styles.link}>
          こちら
        </Link>
      </p>
    </div>
  )
}

export default HomeSigninInputBlock
