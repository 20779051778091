import React, { FC, useState, useEffect } from 'react'
import styled from 'styled-components'
//import { useWindowSize } from 'react-use'
//import { mediaSize, pxToNumber, color } from '@/assets/styles/theme'

export type PaginationProps = {
  /* 初期の表示ページ */
  initialPage: number
  /* 全ページ数 */
  pageCount: number
  /**
   * ページ変更処理
   * @param {number} selected - 遷移ページ数
   */
  onPageChange: ({ selected }: { selected: number }) => void
  /* アクティブなページのカラー */
  color: string
}

const Pagination: FC<PaginationProps> = ({
  initialPage = 0,
  pageCount,
  color = '#0B5DD0',
  onPageChange,
}: PaginationProps) => {
  //const { width } = useWindowSize()
  const [selected, setSelected] = useState<number>(initialPage)

  const isFirstPage = () => selected === 0
  const isLastPage = () => selected === pageCount - 1

  useEffect(() => {
    setSelected(initialPage)
  }, [initialPage, setSelected])


  const handlePreviousPage = (e: any) => {
    e.preventDefault()
    if (isFirstPage()) {
      return
    }
    onPageChange({ selected: selected - 1 })
    setSelected((selected) => selected - 1)
  }

  const handleNextPage = (e: any) => {
    e.preventDefault()
    if (isLastPage()) {
      return
    }
    onPageChange({ selected: selected + 1 })
    setSelected((selected) => selected + 1)
  }

  const handleFirstPage = (e: any) => {
    e.preventDefault()
    if (isFirstPage()) {
      return
    }
    setSelected(0)
    onPageChange({ selected: 0 })
  }
  const handleLastPage = (e: any) => {
    e.preventDefault()
    if (isLastPage()) {
      return
    }
    setSelected(pageCount - 1)
    onPageChange({ selected: pageCount - 1 })
  }

  const handleItemPage = (_e: any, pageIndex: number) => {
    setSelected(pageIndex)
    onPageChange({ selected: pageIndex })
  }

  const createPage = () => {
    let items = []
    for (let index = 0; index < pageCount; index++) {
      const page = index + 1
      const test = createPageItem({
        index: index,
        page: page,
        selected: index === selected,
        handleItemPage: handleItemPage,
      })
      items.push(test)
    }

    if (selected === 0 && pageCount > 3) {
      items = items.splice(0, 3)
    } else if (selected === pageCount - 1 && pageCount > 3) {
      items = items.splice(-3, 3)
    } else if (pageCount > 3) {
      items = items.splice(selected - 1, 3)
    }
    return items
  }

  type PageItemProps = {
    index: number
    page: number
    selected: boolean
    handleItemPage: ({ target: HTMLButtonElement }: any, number: number) => void
  }

  const createPageItem = ({
    index,
    page,
    selected,
    handleItemPage,
  }: PageItemProps) => {
    return (
      <WrapperCreatePageItem key={index} selected={selected} bg_color={color}>
        <div
          onClick={(e) => {
            handleItemPage(e, index)
          }}
        >
          {page}
        </div>
      </WrapperCreatePageItem>
    )
  }

  //   if (width <= pxToNumber(mediaSize.tablet)) {
  //     if (pageCount === 0 || pageCount === 1 || !pageCount) {
  //       return null
  //     }

  //     return (
  //       <Wrapper
  //         selected={selected}
  //         is_first={isFirstPage()}
  //         is_last={isLastPage()}
  //         color={color}
  //       >
  //         <div className="pagination-media-size">
  //           <div className="prev">
  //             <a
  //               onClick={(e) => {
  //                 handlePreviousPage(e)
  //               }}
  //             >
  //               &lt; 前の20件
  //             </a>
  //           </div>
  //           <div className="next">
  //             <a
  //               onClick={(e) => {
  //                 handleNextPage(e)
  //               }}
  //             >
  //               次の20件 &gt;
  //             </a>
  //           </div>
  //         </div>
  //       </Wrapper>
  //     )
  //   }

  if (pageCount === 0 || pageCount === 1 || !pageCount) {
    return null
  }
  return (
    <Wrapper
      selected={selected}
      is_first={isFirstPage()}
      is_last={isLastPage()}
      //color={color}
    >
      <ul className="pagination">
        {pageCount !== 2 && pageCount !== 3 && (
          <li className="first">
            <div onClick={handleFirstPage}>最初</div>
          </li>
        )}
        <li className="prev">
          <div
            onClick={(e) => {
              handlePreviousPage(e)
            }}
          >
            &lt;
          </div>
        </li>

        {createPage()}

        <li className="next">
          <div
            onClick={(e) => {
              handleNextPage(e)
            }}
          >
            &gt;
          </div>
        </li>
        {pageCount !== 2 && pageCount !== 3 && (
          <li className="last">
            <div onClick={handleLastPage}>最後</div>
          </li>
        )}
      </ul>
    </Wrapper>
  )
}

const WrapperCreatePageItem = styled.li.attrs((props: any) => ({ ...props }))`
  color: ${(props) => (props.selected ? 'white' : '#1d9bf0')};
  background-color: ${(props) => (props.selected ? '#1d9bf0' : 'white')};
  border-radius: 50%;
  cursor: pointer;
`

const Wrapper = styled.div.attrs((props: any) => ({ ...props }))`
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  .pagination-media-size {
    overflow: hidden;
    margin: 2rem 3rem;
  }
  .pagination {
    overflow: hidden;
    display: table;
    margin: 20px auto;
    li {
      float: left;
      margin: 0.1rem;
      div {
        display: block;
        padding: 0.5rem 0.75rem;
      }
    }
    .first,
    .prev,
    .next,
    .last {
      background-color: white;
      color: #1d9bf0;
      cursor: pointer;
    }
    .first,
    .last {
      border: 1px solid #1d9bf0;
      border-radius: 20px;
    }
    .prev,
    .next {
      font-weight: bold;
    }
  }
`

export default React.memo(Pagination)
