export const useAppState = () => {
  
  enum KEYS {
    BEFORE_SIGNUP_URL = "beforeSignupUrl", // 新規登録前のURL
  }
  
  const getItem = ( key: KEYS ) : string => {
    const value = localStorage.getItem(key);
    if (value !== null) {
      return value
    }
    return ''
  }

  const setItem = (key: KEYS, value: string) => {
    localStorage.setItem(key, value)
  }

  const removeItem = (key: KEYS) => {
    localStorage.removeItem(key)
  }

  return {getItem, setItem, removeItem, KEYS}
}
