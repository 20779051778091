import { FC, ReactNode, useState, useEffect, useRef } from 'react'
import { useWindowSize } from 'react-use'
import classnames from 'classnames'
import { Header } from 'components'
import Footer from 'components/Footer'
import HowToUseFloat from 'components/HowToUseFloat'
import { useFloat } from 'globalStates/floatState'
import { User } from 'models'

import styles from './index.module.css'

export type LayoutProps = {
  children: ReactNode
  user?: User
  className?: string
  float?: boolean
}

const Layout: FC<LayoutProps> = ({ children, user, className, float = true }) => {
  const [showHowtouse, setShowHowtouse] = useState<boolean>(true);
  const {getItem, setItem, KEYS} = useFloat()
  const [floatHidden, setFloatHidden] = useState<Boolean>(false) //フロート非表示(true)
  const [floatBottom, setFloatBottom] = useState<Boolean>(false) //フロートbottom高い(true)
  const footerRef = useRef<HTMLDivElement>(null) // フッター要素
  const [footerHeight, setFooterHeight] = useState(0) // フッターの高さ
  const { width, height } = useWindowSize() // 表示領域の高さ
  const [point, setPoint] = useState(0) // 現在のスクロール地点
  const docHeight = document.documentElement.offsetHeight // ドキュメントの高さ

  useEffect(() => {
    if(footerRef.current){
      const hight = footerRef.current.getBoundingClientRect().height // フッターの高さ
      setFooterHeight(hight);
    }
  }, [footerRef]);

  const onScroll = (): void => {
    const position = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
    setPoint(position)
  }

  useEffect(() => {
    // 現在のスクロール地点取得
    document.addEventListener("scroll", onScroll)
    if( docHeight === height ) { // ドキュメントの高さ=表示領域:スクロール不要の場合
      setFloatHidden(false)
      setFloatBottom(true)
    }else if( width < 481 && height > (docHeight - footerHeight) ){ // スマホサイズ＋フッター一部表示される場合
      setFloatHidden(false)
      if(10 > height - (docHeight - footerHeight)){ //フッター一部表示される高さが10より低い場合
        setFloatBottom(false)
      } else {
        setFloatBottom(true)
      }
      if((height - (docHeight  - footerHeight)) - point < (height - (docHeight  - footerHeight))){ //スクロールしてフロートがフッターに達した場合
        setFloatHidden(true)
      }
    } else if ( point > docHeight - height - footerHeight ) { // スクロール地点>ドキュメントの高さ-表示領域-footerの高さ
      setFloatHidden(true)
      setFloatBottom(false)
    } else {
      setFloatHidden(false)
      setFloatBottom(false)
    }
    return()=>{
      document.removeEventListener("scroll", onScroll)
    }
  }, [docHeight, footerHeight, height, point, width]);

  useEffect(() => {
    updateLabel();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateLabel = () => {
    setShowHowtouse(!getItem(KEYS.HOTOUSE_FLOAT_HIDDEN))
  };
  
  const handleHide = () => {
    setItem(KEYS.HOTOUSE_FLOAT_HIDDEN, true);
    updateLabel();
  }
  return (
    <div className={styles.body}>
      <Header user={user} />
      <div className={classnames(className, styles.main)}>
        <div className={styles.contents}>{children}</div>
      </div>
      {float &&
        <HowToUseFloat
          show={showHowtouse}
          deleteBtn={true}
          handleHide={handleHide}
          className={classnames(floatHidden ? styles.is_hidden : "", floatBottom ? styles.float_bottom : "",)}
        />
      }
      <div ref={footerRef}>
      <Footer user={user} />
      </div>
    </div>
  )
}

export default Layout
