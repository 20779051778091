import Table from './Table'
export * from './TableHeader'
export * from './TableRow'

export type HeaderType = {
  name: string
  value: string
  isSortEnabled?: boolean
}
export type HeadersType = Array<HeaderType>

export type RowsType = Array<{
  id: number
  [x: string]: string | number | boolean
}>

export default Table
