import { useQuery } from 'react-query'
import axios from 'axios'
import { getHeaders } from 'utils'

const fetcher = async (filename: string) => {
  try {
    const headers = getHeaders()

    if (headers === null) {
      throw new Error('jwtなし')
    }

    const response = await axios.get(`${filename}`, {
      headers: headers,
      data: {},
      responseType: "blob",
    })
    const blob = new Blob([response.data], {
      type: response.data.type
    });

    return {
      blob:blob
    }
  } catch (error: any) {
    return {
      blob:null
    }
  }
}

const useDownloadWithAuth = (filename: string) => {
  const { data, isLoading, isError, error, refetch } = useQuery<any>(
    [`download/${filename}`],
    () => fetcher(filename),
    {
      staleTime: Infinity, // Infinityに設定するとバックグラウンドでフェッチしに行かない
      cacheTime: 3600000  // 60分(60 * 60 * 1000 ms)
    },
  )

  return { data, isLoading, isError, refetch, error }
}

export default useDownloadWithAuth
