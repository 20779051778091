import { useQuery } from 'react-query'
import axios from 'axios'
import { getHeaders } from 'utils'
import { NameCard } from 'models'

const backendUrl = process.env.REACT_APP_API_URL

export const fetcher = async () => {
  try {
    const headers = getHeaders()

    if (headers === null) {
      throw new Error('jwtなし')
    }

    const res = await axios.get(`${backendUrl}/users/me`, {
      headers: headers,
      data: {},
    })

    const { id, username, email, name_cards } = res.data

    let avatarImage = null
    if (name_cards?.length > 0) {
      const defaultCard = name_cards.find(
        (card: NameCard) => card.defaultCard === true,
      )
      avatarImage = defaultCard.avatar
    }

    return {
      login: true,
      username: username,
      email: email,
      id: id,
      errorMessage: '',
      avatar: avatarImage,
      nameCards: name_cards,
    }
  } catch (error: any) {
    return {
      login: false,
      username: '',
      email: '',
      id: -1,
      errorMessage: '',
      avatar: null,
      nameCards: [],
    }
  }
}

const useUsersMe = () => {
  const { data, isLoading, isError, error, refetch } = useQuery<any>(
    ['users-me'],
    () => fetcher(),
    {},
  )

  return { data, isLoading, isError, refetch, error }
}

export default useUsersMe
