import React, { useEffect } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import classnames from 'classnames'
import { create } from 'react-modal-promise'
import { Button, Icon } from 'components'
import styles from './index.module.css'

export const WrapperModal = ({
  isOpen,
  onResolve,
  // onReject,
  text,
  yesNo = 'yes',
  yesText = 'はい',
  noText = 'いいえ',
  // color,
  icon,
}: any) => {
  const submit = () => {
    onResolve(true)
    scrolling()
  }
  const reject = () => {
    onResolve(false) //onReject(false)
    scrolling()
  }

  const getMessage = (text: any) => {
    if (Array.isArray(text)) {
      return (
        <p className={styles.text}>
          {text.map((t, index) => (
            <React.Fragment key={index}>{t}</React.Fragment>
          ))}
        </p>
      )
    }
    return <p className={styles.text}>{text}</p>
  }

  const stopScrollingBackContent = () => {
    // デスクトップ向けの処理
    document.body.style.position = 'fixed'
    document.body.style.width = `100%`
  }

  const scrolling = () => {
    // デスクトップ向けの処理
    document.body.style.position = 'unset'
  }

  useEffect(stopScrollingBackContent, [])

  return (
    <Modal className={styles.modal_box} isOpen={isOpen} toggle={reject}>
      <ModalBody className={styles.modal}>
        {icon && (
          <Icon
            icon={icon}
            iconClassName={styles.icon}
            className={styles.item_icon}
          ></Icon>
        )}
        <div className={styles.text_box}>{getMessage(text)}</div>
        <div className={styles.flex}>
          {yesNo === 'yesNo' && (
            <Button
              size="small"
              color="secondary"
              className={classnames(
                styles.button,
                styles.no_btn,
                styles.delete_no,
              )}
              onClick={reject}
            >
              {noText}
            </Button>
          )}
          <Button
            size="small"
            color="primary"
            className={classnames(
              styles.button,
              styles.yes_btn,
              icon === 'stash' && styles.delete_yes,
            )}
            onClick={submit}
          >
            {yesText}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default create(WrapperModal)
