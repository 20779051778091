import { useEffect, useState } from "react";
import { ImgHTMLAttributes, DetailedHTMLProps, FC } from 'react'
import useDownloadWithAuth from 'hooks/useDownloadWithAuth'
import userIcon from 'assets/images/icons/user.svg'

interface AuthImageProps
  extends DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  src: string
  alt: string
}
const AuthImage: FC<AuthImageProps> = ({
  src,
  alt,
  ...props
}) => {
  const {data} = useDownloadWithAuth(src)
  const [img, setImg] = useState('')
  useEffect(() => {
    if( data?.blob ) {
      const imageObjectURL = URL.createObjectURL(data.blob)
      setImg(imageObjectURL)
    }
  }, [data]);  
  return (
    <>
      { img ? (
        <img
          src={img}
          alt={alt}
          {...props}
        >
          {props.children}
        </img>
      ) : (
        <img
          src={userIcon}
          alt={alt}
          {...props}
        >
          {props.children}
        </img>
      ) }
    </>
  )
}
export default AuthImage
