import { Link } from 'react-router-dom'
import { Head, Icon, LoadingLayout } from 'components'
import styles from './index.module.css'

const MailAuth = () => {
  return (
    <>
      <Head title="メールアドレス認証 | 会議ログβ版" />
      <LoadingLayout link={true}>
        <div className={styles.auth}>
          <Icon icon="auth" className={styles.logo_icon} />
          <p className={styles.text}>メールアドレスの認証に成功しました。</p>
          <p className={styles.text}>登録したメールアドレスとパスワードで<Link to='/'>こちら</Link>からログインしてください。</p>
        </div>
      </LoadingLayout>
    </>
  )
}

export default MailAuth