import { useState } from 'react'
import { useUpdateAuthState } from 'globalStates/authState'
import { getHeaders } from 'utils'
import usePost from './usePost'
import Modal from 'components/Modal'

export type mutateParam = {
  username: string | undefined
  email: string | undefined
  password: string | undefined
  nameCard: {
    name: string | undefined
    email: string | undefined
    defaultCard: boolean
  }
}

export const useCreateUser = () => {
  const [errorInternal, setErrorInternal] = useState<any>(null)
  const updateAuthState = useUpdateAuthState()

  /**
   * ユーザーの作成
   */
  const {
    mutate: mutateN,
    isLoading: isLoadingN,
    error: errorN,
  } = usePost({
    url: `${process.env.REACT_APP_API_URL}/auth/local/register`,
    headers: getHeaders(),
  })

  const mutate = async ({ params }: { params: mutateParam }) => {
    // console.log('params', params)
    const { username, email, password, nameCard } = params

    const NewUser = {
      username,
      email,
      password,
      nameCard,
    }

    return mutateN({ params: NewUser, headers: {} }).then((response) => {
      // console.log('mutateN', response)

      if (response?.status === 200) {
        const { jwt } = response?.data

        if( jwt ) {
          // jwtあり = メール認証なし
          localStorage.setItem('jwt', jwt)
          updateAuthState()
        } else {
          // jwtなし = メール認証あり
          Modal({
            text: '登録したメールアドレスにメールを送信しました。urlをクリックして登録を完了させてください',
            yesNo: 'yes',
            yesText: '閉じる',
            icon: 'error',
          })
        }
        return response
      } else {
        // console.log('check', response?.data)
        const data = response?.data
        const errorMessage = data.message[0].messages[0].message
        setErrorInternal(errorMessage)
        Modal({
          text: errorMessage,
          yesNo: 'yes',
          yesText: '閉じる',
          icon: 'error',
        })
        return response
      }
    })
  }

  return {
    mutate,
    isLoading: isLoadingN,
    error: errorN || errorInternal,
  }
}
