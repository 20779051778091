import { useCallback, useEffect, useMemo, useState } from 'react'
import axios, {
  AxiosError,
  AxiosRequestHeaders,
  AxiosResponse,
  Method,
} from 'axios'

interface Params {
  [key: string]: any
}

interface Args<T> {
  url?: string
  params?: Params
  body?: Params
  headers?: AxiosRequestHeaders | null
  method?: Method
  onSuccess?: (data?: T) => void
  onError?: (err: AxiosError) => void
}

interface PostRequest {
  method?: Method
  url?: string
  params?: Params
  body?: Params
  headers?: AxiosRequestHeaders | null
}

type Mutate<T> = ({ url, params, body, headers }: Args<T>) => Promise<any>

interface PostResponse<T> {
  mutate: Mutate<T>
  isLoading: boolean
  error: any
}

function usePost<T>({
  method = 'post',
  url,
  params,
  headers,
}: PostRequest): PostResponse<T> {
  const methodInternal = useMemo(() => method, [method])
  const urlInternal = useMemo(() => url, [url])
  const paramsInternal = useMemo(() => params, [params])
  const headersInternal = useMemo(() => headers, [headers])
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const mutate = useCallback<Mutate<T>>(
    async <T,>(args: Args<T>) => {
      // console.log('urlInternal', urlInternal)
      const reqUrl = args.url ?? urlInternal ?? ''
      // console.log('reqUrl', reqUrl)
      if (!reqUrl) {
        console.error('can not fount url')
        return
      }
      if (!methodInternal) {
        return
      }
      try {
        // if (!headers) {
        //   return null
        // }

        setLoading(true)
        const res: AxiosResponse<T | any> = await axios.request({
          method: args?.method ?? methodInternal,
          url: reqUrl,
          data: args?.params ?? paramsInternal ?? undefined,
          headers: args?.headers ?? headersInternal ?? {},
        })

        //error handling
        if (res.data?.errors) {
          setError(res.data?.errors)
          args.onError?.(res.data as AxiosError)
          return res.data
        }

        setError(null)
        args.onSuccess?.(res.data)
        // console.log('res1', res.data?.errors)
        return res
      } catch (err: any) {
        args.onError?.(err as AxiosError | any)
        console.log('res2', err)

        return err.response
      } finally {
        setLoading(false)
      }
    },
    [headersInternal, methodInternal, paramsInternal, urlInternal],
  )

  const clear = useCallback(() => {
    setLoading(false)
  }, [])

  useEffect(() => () => clear(), [clear])

  return {
    mutate,
    isLoading,
    error,
  }
}

export default usePost
