import { useState } from 'react'
import { getHeaders } from 'utils'
// import usePost from './usePost'
// import { usePut } from 'hooks'
import axios from 'axios'

export type mutateParam = {
  id: number | null
}

const useUpdateDefaultNameCard = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)

  const mutate = async ({ id }: mutateParam) => {
    if (!id) {
      return null
    }
    setIsLoading(true)
    return axios
      .put<any>(
        `${process.env.REACT_APP_API_URL}/name-cards/default/${id}`,
        {},
        { headers: getHeaders() || undefined },
      )
      .then((response) => {
        // console.log(response)
        return response
      })
      .catch((error) => {
        console.log(error.response)
        setIsLoading(false)
        setError('error')
        return error.response
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return { mutate, isLoading, error }
}

export default useUpdateDefaultNameCard
