import { NameCardList, NameCard } from './NameCard'
import { Image } from './Image'

export interface User {
  id: number
  username: string
  email: string
  avatar: Image | null
  login: boolean
  errorMessage: string | null
  nameCards: NameCardList
}

export const createNullUser = () => {
  return {
    id: 0,
    username: '',
    email: '',
    avatar: null,
    login: false,
    errorMessage: '',
    nameCards: [],
  }
}

/**
 * ユーザーデータから、デフォルト設定の名刺カードを取得する関数
 * @param user
 * @returns
 */
export const getDefaultNameCard = (user: User): NameCard | null => {
  const defaultUser = user.nameCards.find((card) => card.defaultCard === true)

  if (defaultUser) {
    return defaultUser
  } else {
    return null
  }
}

/**
 * ユーザーデータから、デフォルト設定以外の名刺カードのリストを取得する関数
 */
export const getWithoutDefaultNameCardList = (user: User): NameCardList => {
  const withoutDefaultUser = user.nameCards.filter(
    (card) => card.defaultCard === false,
  )
  return withoutDefaultUser
}
