import { FC } from 'react'
import { NameCard } from 'models'
import { AvatarImage, LinkButton } from 'components'
import classnames from 'classnames'
import styles from './index.module.css'

export type NameCardItemProps = {
  namecard: NameCard | null
  edit?: string | null
  profile?: string
  className?: string
  imgClassName?: string
  itemClassName?: string
  profileNameFlg?: boolean
  users?: boolean
  omit?: boolean
}

const NameCardItem: FC<NameCardItemProps> = ({
  namecard,
  edit,
  profile,
  className,
  imgClassName,
  itemClassName,
  profileNameFlg = false,
  users = false,
  omit = false,
}) => {
  if (namecard == null) {
    return null
  }

  const {
    id,
    avatar,
    profileName,
    nameKana,
    name,
    email,
    companyName,
    departmentName,
    positionName,
    tel,
    comment,
  } = namecard

  return (
    <div className={classnames(className, styles.card, users && styles.card_users)}>
      <div className={styles.image}>
        <AvatarImage
          className={classnames(imgClassName, styles.img)}
          src={avatar?.url || ''}
          alt=""
          height={100}
          width={100}
        />
        {profileNameFlg && profileName && (
          <div className={classnames(styles.item, styles.profileName)}>
            {profileName}
          </div>
        )}
        {edit && (
          <LinkButton className={styles.edit} size="mini" color="secondary" to={edit === "user" ? `/user/${id}/edit`:`/user-log/${namecard.id}/edit`}>
            編集
          </LinkButton>
        )}
        {profile && <div className={styles.profile}>{profile}</div>}
        {namecard.user === null && (
          <div className={styles.gest}>ゲストユーザー</div>
        )}
      </div>
      <div
        className={classnames(
          itemClassName,
          edit ? styles.modal_items : styles.items,
        )}
      >
        <div className={styles.item}>
        {departmentName && (
            <div className={classnames(styles.item_department, omit ? styles.modal_item_name : styles.item_name)}>
              {departmentName}
            </div>
        )}
        {positionName && (
            <div className={classnames(departmentName ? styles.item_position : styles.item_position_only, omit ? styles.modal_item_name : styles.item_name)}>
              {positionName}
            </div>
        )}
        </div>
        <div className={classnames(styles.name, styles.item)}>
          <div className={omit ? styles.modal_item_name : styles.item_name}>
            {name}
          </div>
        </div>
        {nameKana && (
          <div className={classnames(styles.item, styles.usernameKana)}>
            <div
              className={
                omit
                  ? styles.modal_item_name
                  : styles.item_name
              }
            >
              {nameKana}
            </div>
          </div>
        )}
        <hr className={styles.hr}/>
        <div className={styles.item}>
          <div className={omit ? styles.modal_item_name : styles.item_name}>
            {companyName}
          </div>
        </div>
        <div className={styles.item}>
          <div className={omit ? styles.modal_item_name : styles.item_name}>
            Mail: {email}
          </div>
        </div>
        {tel && (
          <div className={styles.item}>
            <div className={omit ? styles.modal_item_name : styles.item_name}>
              Tel: {tel}
            </div>
          </div>
        )}
        {comment && (
          <div className={styles.item}>
            <div className={omit ? styles.modal_item_name : styles.item_name}>
              Comment: {comment}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default NameCardItem
