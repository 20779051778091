import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import { Icon } from 'components'
import classnames from 'classnames'
import styles from './index.module.css'

export type HeaderProps = {
  link?: boolean
}

const Header: FC<HeaderProps> = ({ link }) => {
  const { width } = useWindowSize()
  return (
    <div className={styles.header}>
      <div className={styles.wrap}>
        <div className={styles.logo}>
          {link ? (
            <NavLink
              className={classnames(styles.link, styles.logo_link)}
              to="/"
            >
              {width <= 710 ? (
                <Icon icon="logo_small_β" className={styles.logo_icon} />
              ) : (
                <Icon icon="logo_β" className={styles.logo_icon} />
              )}
            </NavLink>
          ) : (
            <div className={classnames(styles.logo_link)}>
              {width <= 710 ? (
                <Icon icon="logo_small_β" className={styles.logo_icon} />
              ) : (
                <Icon icon="logo_β" className={styles.logo_icon} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header
