import { FC } from 'react'
// import styled from 'styled-components'
import { useWindowSize } from 'react-use'
import { Icon } from 'components'
import styles from './index.module.css'
import classnames from 'classnames'

export type TableRowProps = {
  headers: any
  onClick: any
  onSelect: any
  row: any
  rowLinks: any
  withBulkAction: any
}

const TableRow: FC<TableRowProps> = ({
  headers,
  onClick,
  //onSelect,
  row,
  //rowLinks,
}) => {
  const { width } = useWindowSize()
  return (
    <tr
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        onClick(e, row)
      }}
      className={styles.tr}
    >
      {headers.map(
        (
          header: any,
          // { name: string },
          // { value: cellName }: { value: string; cellName: string },
        ) => {
          const { value } = header
          let displayedValue = row[value] || ''
          //let displayedValue = !isObject(row[cellName]) ? row[cellName] : '-'

          // if (isFunction(cellFormatter)) {
          //   displayedValue = cellFormatter(row[cellName], row)
          // }

          let displayedContent = (
            <p className={classnames(styles.item_value,styles[`${value}`])}>{displayedValue || ''}</p>
          )

          if (value === 'consent') {
            if (displayedValue === '未承諾有') {
              displayedContent = (
                <p className={styles.unsolicited}>{displayedValue}</p>
              )
            }
          }

          // if (isFunction(cellAdapter)) {
          //   displayedContent = cellAdapter(row)
          // }

          return (
            <td
              key={value}
              className={classnames(
                styles.td,
                styles[`${value}_cell`],
                value === 'consent' ? styles.consent : '',
              )}
            >
              {/* {value === 'datetime' && width <= 710 && (
                <Icon
                  icon="time"
                  iconClassName={styles.icon}
                  className={styles.item_icon}
                ></Icon>
              )} */}
              {value === 'owner' && width <= 710 && (
                <Icon
                  icon="name"
                  iconClassName={styles.icon}
                  className={styles.item_icon}
                ></Icon>
              )}
              {value === 'company' && width <= 710 && (
                <Icon
                  icon="office"
                  iconClassName={styles.icon}
                  className={styles.item_icon}
                ></Icon>
              )}
              {displayedContent}
            </td>
          )
        },
      )}
    </tr>
  )
}

export default TableRow
