import { Menu, Icon } from 'components'
import { NameCard, User } from 'models'
import { FC, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useAuthState } from 'globalStates/authState'
import { useWindowSize } from 'react-use'
import classnames from 'classnames'
import styles from './index.module.css'

import { AvatarImage } from 'components'

import useUpdateDefaultNameCard from 'hooks/useUpdateDefaultNameCard'

export type HeaderProps = {
  user?: User
}

const Header: FC<HeaderProps> = ({ user }) => {
  const [authState, setAuthState] = useAuthState()
  const { mutate } = useUpdateDefaultNameCard()
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const { width } = useWindowSize()

  const handleUpdateDefaultNameCard = (card: NameCard) => {
    const nameCards = authState.nameCards
    const updateNameCards = nameCards.map((n) => ({
      ...n,
      defaultCard: card.id === n.id ? true : false,
    }))

    setAuthState({
      ...authState,
      avatar: card.avatar,
      nameCards: updateNameCards,
    })

    mutate({ id: card.id })
  }

  const handleOpenMenu = () => {
    setShowMenu(!showMenu)
  }

  // if (isLoading) {
  //   return <div>loading..</div>
  // }

  return (
    <div className={styles.header}>
      <div className={styles.wrap}>
        <div className={styles.logo}>
          <NavLink
            className={classnames(styles.link, styles.logo_link)}
            to="/top"
          >
            {width <= 710 ? (
              <Icon icon="logo_small_β" className={styles.logo_icon} />
            ) : (
              <Icon icon="logo_β" className={styles.logo_icon} />
            )}
          </NavLink>
          <Link className={styles.contact} to="/contact">
            {width <= 480 ? (
              <>
                ご意見
                <br />
                ご要望
              </>
            ) : (
              <>ご意見・ご要望</>
            )}
          </Link>
        </div>
        {user && (
          <>
            <div className={styles.menu}>
              <div className={styles.menu_item}>
                <NavLink
                  className={classnames(styles.link, styles.menu_link)}
                  style={({ isActive }) =>
                    isActive ? { color: '#1D9BF0' } : { color: 'white' }
                  }
                  to="/top"
                  children={({ isActive }) => {
                    const icon = isActive ? 'home_active' : 'home'
                    return (
                      <>
                        <Icon
                          icon={icon}
                          iconClassName={styles.icon}
                          className={styles.menu_icon}
                        />
                        <div className={styles.text}>TOP</div>
                      </>
                    )
                  }}
                />
              </div>
              <div className={styles.menu_item}>
                <NavLink
                  className={classnames(styles.link, styles.menu_link)}
                  style={({ isActive }) =>
                    isActive ? { color: '#1D9BF0' } : { color: 'white' }
                  }
                  to="/meeting/list"
                  children={({ isActive }) => {
                    const icon = isActive ? 'meeting_active' : 'meeting'
                    return (
                      <>
                        <Icon
                          icon={icon}
                          iconClassName={styles.icon}
                          className={styles.menu_icon}
                        />
                        <div className={styles.text}>会議情報</div>
                      </>
                    )
                  }}
                />
              </div>
            </div>
            <div className={styles.sub_menu}>
              <NavLink
                className={classnames(styles.link, styles.create)}
                to="/meeting/new"
              >
                <Icon
                  icon="pen"
                  iconClassName={styles.icon}
                  className={styles.button_icon}
                ></Icon>
                {width <= 710 ? <>会議作成</> : <>会議情報作成</>}
              </NavLink>
              <div
                className={styles.profile}
                onClick={() => handleOpenMenu()}
              >
                <AvatarImage
                  className={styles.img}
                  src={user.avatar?.url || ''}
                  alt="user"
                />
              </div>
              <Menu
                user={user}
                show={showMenu}
                onClose={()=>{setShowMenu(false)}}
                onChangeUser={handleUpdateDefaultNameCard}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Header
