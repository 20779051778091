//import React from 'react'
import { FC } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { Icon } from 'components'
import { IconType } from 'components/Icon'
import classnames from 'classnames'
import styles from './index.module.css'

export interface LinkButtonType extends LinkProps {
  to: string
  size?: 'big' | 'small' | 'mini'
  color?: 'primary' | 'secondary' | 'sns' | 'orange'
  icon?: IconType
  className?: string
}

const LinkButton: FC<LinkButtonType> = ({
  to,
  size = 'big',
  color = 'primary',
  icon,
  className,
  ...props
}) => {
  return (
    <>
      {icon ? (
        <Link
          className={classnames(
            className,
            styles.button,
            styles[color],
            styles[size],
          )}
          to={to}
          {...props}
        >
          <Icon
            icon={icon}
            iconClassName={styles.icon}
            className={styles.link_icon}
          ></Icon>
          {props.children}
        </Link>
      ) : (
        <Link
          className={classnames(
            className,
            styles.button,
            styles[color],
            styles[size],
          )}
          to={to}
          {...props}
        >
          {props.children}
        </Link>
      )}
    </>
  )
}

export default LinkButton
