import React, { Suspense } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { QueryClient, QueryClientProvider } from 'react-query'
import ModalContainer from 'react-modal-promise'

import { RecoilRoot } from 'recoil'
import AuthRouter from 'components/AuthRouter'
import Home from 'views/Home'
import SnsAuth from 'views/SnsAuth'
import MailAuth from 'views/MailAuth'
// import GA4Router from 'components/GA4Router'
// import { createBrowserHistory } from 'history';

//import EditNameCardPage from './views/EditNameCardPage'
//import EditUserLogPage from "./views/EditUserLogPage"

import { useAuthValue } from 'globalStates/authState'
import { Loading } from 'components'

const Page404 = React.lazy(() => import('./views/404'))
const Top = React.lazy(() => import('./views/Top'))
const ResetPasswordPage = React.lazy(() => import('./views/ResetPasswordPage'))
const TermsOfService = React.lazy(() => import('./views/TermsOfService'))
const PrivacyPolicy = React.lazy(() => import('./views/PrivacyPolicy'))
const SecurityPolicy = React.lazy(() => import('./views/SecurityPolicy'))

const MeetingListPage = React.lazy(() => import('./views/MeetingListPage'))
const MeetingDetailPage = React.lazy(() => import('./views/MeetingDetailPage'))

const EditNameCardPage = React.lazy(() => import('./views/EditNameCardPage'))
const EditUserLogPage = React.lazy(() => import('./views/EditUserLogPage'))

const HowToUsePage = React.lazy(() => import('./views/HowToUse'))
const ContactPage = React.lazy(() => import('./views/ContactPage'))
const MyPage = React.lazy(() => import('./views/MyPage'))
const CreateNameCardPage = React.lazy(
  () => import('./views/CreateNameCardPage'),
)
const CreateMeetingUserNameCardPage = React.lazy(
  () => import('./views/CreateMeetingUserNameCardPage'),
)
const CreateMeetingPage = React.lazy(() => import('./views/CreateMeetingPage'))
const EditMeetingPage = React.lazy(() => import('./views/EditMeetingPage'))
const EditUserMailPage = React.lazy(() => import('./views/EditUserMailPage'))
const EditUserPasswordPage = React.lazy(
  () => import('./views/EditUserPasswordPage'),
)

//const EditNameCardPage = React.lazy(() => import('./views/EditNameCardPage'))

// import { useAuthMutators } from 'globalStates/authState'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      //retry: true,
      refetchOnWindowFocus: true,
      // staleTime: 300000,
    },
  },
})

function App() {
  const user = useAuthValue()

  return (
    <QueryClientProvider client={queryClient}>
      {/* GA4を使用する場合はGA4Router、通常はBrowserRouterを使用する*/}
      {/* <GA4Router history={createBrowserHistory()} user={user}> */}
      <BrowserRouter>
        <Routes>
          {/* un-authored page */}
          <Route path="/" element={<Home user={user} />} />
          <Route path="/auth" element={<SnsAuth />} />
          <Route path="/email-confirmed" element={<MailAuth />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/security-policy" element={<SecurityPolicy />} />
          <Route path="/how-to-use" element={<HowToUsePage user={user} />} />
          <Route path="/contact" element={<ContactPage user={user} />} />

          <Route path="/404" element={<Page404 user={user} />} />

          {/* authored page */}
          <Route element={<AuthRouter />}>
            <Route path="/top" element={<Top user={user} />} />
            <Route path="/user" element={<MyPage user={user} />} />
            <Route
              path="/user/new"
              element={<CreateNameCardPage user={user} />}
            />
            <Route
              path="/meeting/:id/new-user"
              element={<CreateMeetingUserNameCardPage user={user} />}
            />
            <Route
              path="/user-mail/edit"
              element={<EditUserMailPage user={user} />}
            />
            <Route
              path="/user-password/edit"
              element={<EditUserPasswordPage user={user} />}
            />
            <Route
              path="/user/:cardId/edit"
              element={<EditNameCardPage user={user} />}
            />
            <Route
              path="/user-log/:logId/edit"
              element={<EditUserLogPage user={user} />}
            />
            <Route
              path="/meeting/list"
              element={<MeetingListPage user={user} />}
            />
            <Route
              path="/meeting/:id"
              element={<MeetingDetailPage user={user} />}
            />
            <Route
              path="/meeting/:id/edit"
              element={<EditMeetingPage user={user} />}
            />
            <Route
              path="/meeting/new"
              element={<CreateMeetingPage user={user} />}
            />
          </Route>
          <Route path="*" element={<Page404 user={user} />} />
        </Routes>
      {/* </GA4Router> */}
      </BrowserRouter>
    </QueryClientProvider>
  )
}

const AppWrapper = () => {
  return (
    <RecoilRoot>
      <Suspense fallback={<Loading />}>
        <ModalContainer />
        <App />
      </Suspense>
    </RecoilRoot>
  )
}

export default AppWrapper
