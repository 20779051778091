import { FC } from 'react'
import { Link } from 'react-router-dom'
import {
  User,
  getDefaultNameCard,
  getWithoutDefaultNameCardList,
  NameCard,
} from 'models'
import { AvatarImage, Icon, NameCardItem } from 'components'

import { useAuthState } from 'globalStates/authState'

import styles from './index.module.css'

export type MenuProps = {
  user: User
  show: boolean
  onClose: () => void
  onChangeUser: (card: NameCard) => void
}

const Menu: FC<MenuProps> = ({ user, show = false, onClose, onChangeUser }) => {
  const defaultNameCard = getDefaultNameCard(user)
  const withoutDefaultNameCards = getWithoutDefaultNameCardList(user)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setAuthState] = useAuthState()

  const handleLogout = () => {
    localStorage.clear()
    setAuthState({
      id: -1,
      username: '',
      email: '',
      avatar: null,
      login: false,
      errorMessage: null,
      nameCards: [],
    })
    window.location.href = '/';
  }
  const onClickAway = () => {
    onClose()
  }

  if (!show) {
    return null
  }

  return (
    <>
    <div className={styles.menu_back} onClick={()=>{onClickAway()}} >
    </div>
    <div
      className={styles.menu}
      onClick={(event) => {
        event.stopPropagation()
      }}
    >
      <div className={styles.profile_name}>{defaultNameCard?.profileName}</div>
      <NameCardItem
        className={styles.card}
        edit="user"
        namecard={defaultNameCard}
        omit={true}
      />
      <hr />
      {withoutDefaultNameCards.map((card) => (
        <div className={styles.profile} onClick={() => onChangeUser(card)} key={card.id}>
          <AvatarImage
            className={styles.img}
            src={card.avatar?.url || ''}
            alt=""
            height={100}
            width={100}
          />
          <div className={styles.profile_names} key={card.id}>
            {card.profileName}
          </div>
        </div>
      ))}
      <Link className={styles.wrap} to="/user/new">
        <Icon
          icon="add"
          iconClassName={styles.icon}
          className={styles.item_icon}
        ></Icon>
        追加
      </Link>
      <hr />
      <Link className={styles.wrap} to="/how-to-use">
        <Icon
          icon="document"
          iconClassName={styles.icon}
          className={styles.item_icon}
        />
        使い方
      </Link>
      <Link className={styles.wrap} to="/user">
        <Icon
          icon="setting"
          iconClassName={styles.icon}
          className={styles.item_icon}
        />
        設定
      </Link>
      <div
        className={styles.wrap}
        onClick={() => {
          handleLogout()
        }}
      >
        <Icon
          icon="logout"
          iconClassName={styles.icon}
          className={styles.item_icon}
        ></Icon>
        ログアウト
      </div>
    </div>
    </>
  )
}

export default Menu
