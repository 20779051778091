import { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react'
import classnames from 'classnames'
import { Icon } from 'components'
import { IconType } from 'components/Icon'
import styles from './index.module.css'

interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  size?: 'big' | 'small' | 'mini'
  color?: 'primary' | 'secondary' | 'gray' | 'transparent' | 'white'
  icon?: IconType
  className?: string
}
const Button: FC<ButtonProps> = ({
  size = 'big',
  color = 'primary',
  icon,
  className,
  ...props
}) => {
  return (
    <>
      {icon ? (
        <button
          className={classnames(
            className,
            styles.button,
            styles[color],
            styles[size],
          )}
          {...props}
        >
          {props.children}
          <Icon
            icon={icon}
            iconClassName={styles.icon}
            className={styles.btn_icon}
          ></Icon>
        </button>
      ) : (
        <button
          className={classnames(
            className,
            styles.button,
            styles[color],
            styles[size],
          )}
          {...props}
        >
          {props.children}
        </button>
      )}
    </>
  )
}
export default Button
