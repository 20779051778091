import { FC, useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Head, Loading } from 'components'
import HowToUseFloat from 'components/HowToUseFloat'
import Footer from 'components/Footer'

import HomeHeaderBlock from './blocks/HomeHeaderBlock'
import HomeSigninInputBlock, {
  SigninInputType,
} from './blocks/HomeSigninInputBlock'
import HomeSignupInputBlock, {
  SignupInputType,
} from './blocks/HomeSignupInputBlock'

import { useSignin } from 'globalStates/authState'
import { useCreateUser } from 'hooks/useCreateUser'
import { User } from 'models'

import styles from './index.module.css'
import { useAppState } from 'globalStates/appState'

export type HomeProps = {
  user: User
}
const Home: FC<HomeProps> = ({ user }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const {getItem, setItem, removeItem, KEYS} = useAppState()
  const [blockType, setBlockType] = useState<'signin' | 'signup'>('signin')
  const { mutate, isLoading, error } = useSignin()
  const {
    mutate: mutateC,
    isLoading: isLoadingC,
    error: errorC,
  } = useCreateUser()

  // console.log('errorC', errorC)
  // console.log('location', location)

  useEffect(() => {
    if (user.login === true) {
      const beforeUrl = getItem(KEYS.BEFORE_SIGNUP_URL)
      if ( beforeUrl ) {
        // 新規登録前のURLが存在した場合はそこに遷移
        removeItem(KEYS.BEFORE_SIGNUP_URL)
        navigate(beforeUrl)
      } else if (location?.state) {
        // ログイン画面に来る前のURLが存在した場合はそこに遷移
        const state = location.state as string
        location.state = null
        navigate(state)
      } else {
        navigate('/top')
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, user.login, location])

  /**
   * ログイン処理
   * @param data
   */
  const handleSignin = async (data: SigninInputType): Promise<any> => {
    const { email, password } = data
    try {
      await mutate({ params: { identifier: email, password } })
      if (user.login === true) {
        navigate('/top')
      }
    } catch (error: any) {
      console.log('error', error)
    }
  }

  /**
   * 新規登録処理
   * @param data 
   */
  const handleSignup = async (data: SignupInputType) => {
    // console.log('data', data)
    const { username, email, password, company, department } = data

    const defaultNameCard = {
      name: username,
      email,
      defaultCard: true,
      companyName: company || '',
      departmentName: department || '',
    }

    try {
      const response = await mutateC({
        params: { username, email, password, nameCard: defaultNameCard },
      })
      if (response?.status === 200) {
        if (location?.state) {
          // ログイン画面に来る前のURLが存在した場合はパスを保存
          const state = location.state as string
          setItem(KEYS.BEFORE_SIGNUP_URL, state)
          console.log('ログイン画面に来る前のURLが存在した場合はパスを保存')
        }
      }
    } catch (error: any) {
      console.log('error', error)
    }
  }

  const handleSwitchInputBlock = () => {
    setBlockType((i) => (i === 'signin' ? 'signup' : 'signin'))
  }

  const SwitchBlock = () => {
    switch (blockType) {
      case 'signin':
        return (
          <HomeSigninInputBlock
            onClickSignin={handleSignin}
            onClickChange={handleSwitchInputBlock}
            errorMessage={user?.errorMessage}
          />
        )
      case 'signup':
        return (
          <HomeSignupInputBlock
            onClickSignup={handleSignup}
            onClickChange={handleSwitchInputBlock}
            errorMessage={user?.errorMessage || error || errorC}
          />
        )
      default:
        return <div>test</div>
    }
  }

  if (isLoading || isLoadingC) {
    return <Loading />
  }

  // if (error || errorC) {
  //   console.log(error)
  //   return <div>error</div>
  // }

  return (
    <>
      <Head title="会議ログβ版" />
      <div className={styles.home}>
        <div className={styles.main}>
          <HomeHeaderBlock />
          {<SwitchBlock />}
          <HowToUseFloat show={true} />
        </div>
        <Footer className={styles.home_footer}/>
      </div>
    </>
  )
}

export default Home
