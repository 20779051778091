import { useState } from 'react'
import {
  //RecoilRoot,
  atom,
  selector,
  useRecoilState,
  //useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
  // useResetRecoilState,
} from 'recoil'
import usePost from 'hooks/usePost'
import { User } from 'models'
import { fetcher } from 'hooks/useUsersMe'

type AuthState = User
// {
//   login: boolean
//   username: string
//   email: string
//   id: number
//   errorMessage: string
// }

const authRecoilState = atom<AuthState>({
  key: 'authState',
  default: selector({
    key: 'authSelector',
    get: fetcher,
  }),
})

export const useAuthStateLoadable = () => {
  return useRecoilValueLoadable(authRecoilState)
}
export const useAuthValue = () => useRecoilValue(authRecoilState)
export const useAuthState = () => useRecoilState(authRecoilState)

// ユーザー情報を更新する
// export const useUpdateAuthState = () => useResetRecoilState(authRecoilState)
export const useUpdateAuthState = () => {
  // なぜかuseResetRecoilStateだと失敗することがあるので自前でAPI叩いてsetする
  const setAuthState = useSetRecoilState(authRecoilState)

  const mutate = async () => {
    setAuthState( await fetcher() )
  }
  return mutate
}

export const useSignin = () => {
  const [error, setError] = useState<any>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setAuthState] = useAuthState()
  const {
    mutate: mutateS,
    isLoading,
    //error,
  } = usePost({
    url: `${process.env.REACT_APP_API_URL}/auth/local`,
  })

  const mutate = ({ params }: { params: any }) => {
    // console.log('params', params)

    return mutateS({ params: params }).then(async (response) => {
      // console.log('mutate', response)

      if (response?.status === 200) {
        const { jwt, user } = response.data
        const { id, username, email, name_cards } = user
        localStorage.setItem('jwt', jwt)
        // console.log(jwt)

        setAuthState({
          id,
          login: true,
          username,
          email,
          errorMessage: '',
          avatar: null,
          nameCards: name_cards,
        })

        return response
      } else {
        // console.log('tset', response)
        // console.log(response?.data?.data[0].messages[0].message)
        const errorMessage = response.data.data[0].messages[0].message
        setAuthState({
          id: -1,
          login: false,
          username: '',
          email: '',
          errorMessage: errorMessage,
          avatar: null,
          nameCards: [],
        })
        setError(errorMessage)
        return response
      }
    })
  }
  return { mutate, isLoading, error }
}
// export const useAuthMutators = () => {
//   const setState = useSetRecoilState(authRecoilState)

//   console.log('setState', setState)

//   const setAuth = React.useCallback(
//     (authType: AuthType) => setState({ authType: authType }),
//     [setState],
//   )

//   return { setAuth }
// }
