import { FC, ReactNode } from 'react'
import classnames from 'classnames'
import styles from './index.module.css'

export type TitleCardData = {
  children: ReactNode
  color?: 'primary' | 'secondary'
  className?: string
}

const TitleCard: FC<TitleCardData> = ({
  color = 'primary',
  children,
  className,
}) => {
  return (
    <div className={classnames(className, styles.card, styles[color])}>
      {children}
    </div>
  )
}

export default TitleCard
