import { FC } from 'react'
import LoadingLayout from './LoadingLayout'
// import { createNullUser } from 'models'
import { Oval } from 'react-loader-spinner'
import styles from './index.module.css'
import classnames from 'classnames'

export type loadingProps = {
  text?: string
}

const Loading: FC<loadingProps> = ({
  text,
}) => {
  return (
    <LoadingLayout>
      <div className={classnames(styles.overlay_loading,styles.text_loading)}>
        <Oval />
        {text && (
          <>
            <p className={styles.text}>{text}</p>
            <p>完了するまでお待ちください。</p>
          </>
        )}
      </div>
    </LoadingLayout>
  )
}

export default Loading
