import { ImgHTMLAttributes, DetailedHTMLProps, FC } from 'react'
import userIcon from 'assets/images/icons/user.svg'
import AuthImage from 'components/AuthImage'

interface AvatarImageProps
  extends DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  src: string
  alt: string
}
const AvatarImage: FC<AvatarImageProps> = ({
  src,
  alt,
  ...props
}) => {
  return (
    <>
      { src.length > 0 ? (
        <AuthImage
          src={src}
          alt={alt}
          {...props}
        />
      ) : (
        <img
          src={userIcon}
          alt={alt}
          {...props}
        >
          {props.children}
        </img>
      ) }
    </>
  )
}
export default AvatarImage
