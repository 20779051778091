import { FC } from 'react'
import TableHeader from './TableHeader'
import TableRow from './TableRow'
// import classnames from 'classnames'
import styles from './index.module.css'
import './index.module.css'

import { HeadersType, RowsType } from '.'

export type TableProps = {
  className?: string
  rows: RowsType
  headers: HeadersType
  loading?: boolean
  onChangeSort?: any
  onClickRow?: any
  onSelect?: any
  onSelectAll?: any
  rowLinks?: any
  withBulkAction?: any
}

const Table: FC<TableProps> = ({
  className,
  rows,
  loading,
  headers,
  onClickRow,
  onChangeSort,
  onSelect,
  rowLinks,
  withBulkAction,
}) => {
  const shouldDisplayEmptyRow = rows.length === 0 && !loading

  return (
    <table className={className}>
      <TableHeader headers={headers} onChangeSort={onChangeSort} />
      <tbody>
        {!shouldDisplayEmptyRow ? (
          rows.map((row, index) => {
            const key = `key${index}` || row.id
            return (
              <TableRow
                key={key}
                headers={headers}
                onClick={onClickRow}
                onSelect={() => {
                  onSelect(row, index)
                }}
                row={row}
                rowLinks={rowLinks}
                withBulkAction={withBulkAction}
              />
            )
          })
        ) : (
          <tr className={styles.tr_no}>
            <td className={styles.td_no} colSpan={5}>
              会議情報はありません。
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default Table
