import { FC } from 'react'
import { Helmet } from 'react-helmet-async'

type HeadProps = {
  title?: string
  description?: string
  path?: string
}

const Head: FC<HeadProps> = ({title}) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  )
}
export default Head