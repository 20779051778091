import { FC } from 'react'
import classnames from 'classnames'

//const twitter = React.lazy(()=>import("../../assets/images/icons/twitter.svg"))

import accountIcon from '../../assets/images/icons/account.svg'
import addIcon from '../../assets/images/icons/add.svg'
import authIcon from '../../assets/images/icons/auth.svg'
import cameraIcon from '../../assets/images/icons/camera.svg'
import commentIcon from '../../assets/images/icons/comment.svg'
import checkIcon from '../../assets/images/icons/check.svg'
import departmentIcon from '../../assets/images/icons/department.svg'
import documentIcon from '../../assets/images/icons/document.svg'
import documentBlueIcon from '../../assets/images/icons/document_blue.svg'
import documentWhiteIcon from '../../assets/images/icons/document_white.svg'
import downIcon from '../../assets/images/icons/down.svg'
import errorIcon from '../../assets/images/icons/error.svg'
import faviconIcon from '../../assets/images/icons/favicon.svg'
import googleIcon from '../../assets/images/icons/google.svg'
import homeIcon from '../../assets/images/icons/home.svg'
import homeActiveIcon from '../../assets/images/icons/home_active.svg'
import homeNoIcon from '../../assets/images/icons/home_no.svg'
import linkIcon from '../../assets/images/icons/link.svg'
import lockIcon from '../../assets/images/icons/lock.svg'
import logoIcon from '../../assets/images/icons/logo.svg'
import logoSmallIcon from '../../assets/images/icons/logo_small.svg'
import logoβIcon from '../../assets/images/icons/logo_β.svg'
import logoSmallβIcon from '../../assets/images/icons/logo_small_β.svg'
import logoutIcon from '../../assets/images/icons/logout.svg'
import mailIcon from '../../assets/images/icons/mail.svg'
import meetingIcon from '../../assets/images/icons/meeting.svg'
import meetingActiveIcon from '../../assets/images/icons/meeting_active.svg'
import meetingNoIcon from '../../assets/images/icons/meeting_no.svg'
import memoIcon from '../../assets/images/icons/memo.svg'
import nameIcon from '../../assets/images/icons/name.svg'
import nameBlackIcon from '../../assets/images/icons/name_black.svg'
import officeIcon from '../../assets/images/icons/office.svg'
import passIcon from '../../assets/images/icons/pass.svg'
import pass2Icon from '../../assets/images/icons/pass_2.svg'
import penIcon from '../../assets/images/icons/pen.svg'
import plusIcon from '../../assets/images/icons/plus.svg'
import positionIcon from '../../assets/images/icons/position.svg'
import requestIcon from '../../assets/images/icons/request.svg'
import sendIcon from '../../assets/images/icons/send.svg'
import settingIcon from '../../assets/images/icons/setting.svg'
import stashIcon from '../../assets/images/icons/stash.svg'
import stashBtnIcon from '../../assets/images/icons/stash_btn.svg'
import telIcon from '../../assets/images/icons/tel.svg'
import timeIcon from '../../assets/images/icons/time.svg'
import uncheckIcon from '../../assets/images/icons/uncheck.svg'
import upIcon from '../../assets/images/icons/up.svg'
import userIcon from '../../assets/images/icons/user.svg'
import styles from './index.module.css'

import docIcon from '../../assets/images/file/doc.png'
import fileIcon from '../../assets/images/file/file.png'
import imgIcon from '../../assets/images/file/img.png'
import movieIcon from '../../assets/images/file/movie.png'
import pdfIcon from '../../assets/images/file/pdf.png'
import pptIcon from '../../assets/images/file/ppt.png'
import xlsIcon from '../../assets/images/file/xls.png'
import zipIcon from '../../assets/images/file/zip.png'

export type IconType =
  | 'account'
  | 'add'
  | 'auth'
  | 'camera'
  | 'comment'
  | 'check'
  | 'department'
  | 'document'
  | 'document_blue'
  | 'document_white'
  | 'down'
  | 'error'
  | 'favicon'
  | 'google'
  | 'home'
  | 'home_active'
  | 'home_no'
  | 'link'
  | 'lock'
  | 'logo'
  | 'logo_small'
  | 'logo_β'
  | 'logo_small_β'
  | 'logout'
  | 'mail'
  | 'meeting'
  | 'meeting_active'
  | 'meeting_no'
  | 'memo'
  | 'name'
  | 'name_black'
  | 'office'
  | 'pass'
  | 'pass2'
  | 'pen'
  | 'plus'
  | 'position'
  | 'request'
  | 'send'
  | 'setting'
  | 'stash'
  | 'stash_btn'
  | 'tel'
  | 'time'
  | 'uncheck'
  | 'up'
  | 'user'
  | 'doc'
  | 'file'
  | 'img'
  | 'movie'
  | 'pdf'
  | 'ppt'
  | 'xls'
  | 'zip'

export type IconProps = {
  icon: IconType
  className?: string
  iconClassName?: string
}

export const iconMap = new Map<IconType, string>([
  ['account', accountIcon],
  ['add', addIcon],
  ['auth', authIcon],
  ['camera', cameraIcon],
  ['comment', commentIcon],
  ['check', checkIcon],
  ['department', departmentIcon],
  ['document', documentIcon],
  ['document_blue', documentBlueIcon],
  ['document_white', documentWhiteIcon],
  ['down', downIcon],
  ['error', errorIcon],
  ['favicon', faviconIcon],
  ['google', googleIcon],
  ['home', homeIcon],
  ['home_active', homeActiveIcon],
  ['home_no', homeNoIcon],
  ['link', linkIcon],
  ['lock', lockIcon],
  ['logo', logoIcon],
  ['logo_small', logoSmallIcon],
  ['logo_β', logoβIcon],
  ['logo_small_β', logoSmallβIcon],
  ['logout', logoutIcon],
  ['mail', mailIcon],
  ['meeting', meetingIcon],
  ['meeting_active', meetingActiveIcon],
  ['meeting_no', meetingNoIcon],
  ['memo', memoIcon],
  ['name', nameIcon],
  ['name_black', nameBlackIcon],
  ['office', officeIcon],
  ['pass', passIcon],
  ['pass2', pass2Icon],
  ['pen', penIcon],
  ['plus', plusIcon],
  ['position', positionIcon],
  ['request', requestIcon],
  ['send', sendIcon],
  ['setting', settingIcon],
  ['stash', stashIcon],
  ['stash_btn', stashBtnIcon],
  ['tel', telIcon],
  ['time', timeIcon],
  ['uncheck', uncheckIcon],
  ['up', upIcon],
  ['user', userIcon],
  ['doc', docIcon],
  ['file', fileIcon],
  ['img', imgIcon],
  ['movie', movieIcon],
  ['pdf', pdfIcon],
  ['ppt', pptIcon],
  ['xls', xlsIcon],
  ['zip', zipIcon],
])

const Icon: FC<IconProps> = ({ icon, className, iconClassName }) => {
  if (iconMap.has(icon)) {
    return (
      <div className={iconClassName}>
        <img
          src={iconMap.get(icon)}
          className={classnames(styles.icon, className || undefined)}
          alt={icon}
        />
      </div>
    )
  }

  return null
}

// const Wrapper = styled.div``

export default Icon
