const getHeaders = () => {
  const jwt = localStorage.getItem('jwt')

  if (!jwt) {
    return null
  }

  return { Authorization: `Bearer ${jwt}` }
}

export default getHeaders
