import { FC} from 'react'
import { Icon, Button, LinkButton } from 'components'
import styles from './index.module.css'
import classnames from 'classnames'

export type HowToUseFloatProps = {
  show?: boolean
  deleteBtn?: boolean
  handleHide?: any
  className?:string
}

const HowToUseFloat: FC<HowToUseFloatProps> = ({ show, deleteBtn = false, handleHide, className }) => {
  if (!show) {
    return null
  }

  return (
    <div className={classnames(className, styles.float)}>
      {deleteBtn && (
        <Button size='mini' color='white' className={styles.delete} onClick={() => {handleHide()}}>✕</Button>
      )}
      <LinkButton color='orange' className={styles.float_button} to="/how-to-use">
        <Icon
          icon="document_white"
          iconClassName={styles.icon}
          className={styles.item_icon}
        />使い方はこちら　 ＞
      </LinkButton>
    </div>
  )
}

export default HowToUseFloat
