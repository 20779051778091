export const useFloat = () => {
  
  enum KEYS {
    HOTOUSE_FLOAT_HIDDEN = "howtouseFloatHidden",
  }
  
  const getItem = (key:KEYS) : boolean => {
    const value = localStorage.getItem(key);
    if (value !== null) {
      return value === 'true';
    }
    return false;
  }

  const setItem = (key:KEYS, value: boolean) => {
    localStorage.setItem(key, String(value));
  }

  return {getItem, setItem, KEYS}
}
