import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { Button } from 'components'
import { Icon } from 'components'
import classnames from 'classnames'
import styles from '../index.module.css'

export interface SignupInputType {
  username: string
  password: string
  email: string
  company: string
  department: string
}

export type HomeSigninBlockProps = {
  onClickChange: () => void
  onClickSignup: (data: SignupInputType) => void
  errorMessage: string | null
}

/** 会員登録 */
const HomeSignupInputBlock: FC<HomeSigninBlockProps> = ({
  onClickChange,
  onClickSignup,
}) => {
  // 利用規約のチェック
  const [check, setCheck] = useState<boolean>(false)
  const {
    register,
    handleSubmit,
    //watch,
    formState: { errors },
  } = useForm<SignupInputType>()

  return (
    <>
      <form onSubmit={handleSubmit(onClickSignup)}>
        <div className={styles.relative}>
          <div className={styles.icon}>
            <Icon
              icon="name"
              iconClassName={styles.icon}
              className={styles.input_icon}
            ></Icon>
          </div>
          <div className={classnames(styles.required, styles.label)}>
            <div className={styles.required_text}>必須</div>
          </div>
          <input
            className={styles.input}
            placeholder="お名前"
            {...register('username', {
              required: 'お名前を入力してください。',
            })}
          />
        </div>
        {errors.username && (
          <div className={styles.error}>{errors.username?.message}</div>
        )}

        <div className={styles.relative}>
          <div className={styles.icon}>
            <Icon
              icon="mail"
              iconClassName={styles.icon}
              className={styles.input_icon}
            ></Icon>
          </div>
          <div className={classnames(styles.required, styles.label)}>
            <div className={styles.required_text}>必須</div>
          </div>
          <input
            className={styles.input}
            placeholder="メールアドレス"
            {...register('email', {
              required: 'メールアドレスを入力してください。',
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/,
                message: '正しいメールアドレスを入力してください。',
              },
            })}
          />
        </div>
        {errors.email && (
          <div className={styles.error}>{errors.email?.message}</div>
        )}

        <div className={styles.relative}>
          <div className={styles.icon}>
            <Icon
              icon="pass"
              iconClassName={styles.icon}
              className={styles.input_icon}
            ></Icon>
          </div>
          <div className={classnames(styles.required, styles.label)}>
            <div className={styles.required_text}>必須</div>
          </div>
          <input
            className={styles.input}
            type="password"
            placeholder="パスワード"
            {...register('password', {
              required: 'パスワードを入力してください。',
              pattern: {
                value: /^([a-zA-Z0-9!"#$%&()*+,-./:;<=>?@^_`{|}~]{8,50})$/,
                message: '8文字以上50文字以下の半角英数字、半角記号（!"#$%&()*+,-./:;<=>?@^_`{|}~）を入力してください。',
              },
            })}
          />
        </div>
        {errors.password && (
          <div className={styles.error}>{errors.password?.message}</div>
        )}

        <div className={styles.relative}>
          <div className={styles.icon}>
            <Icon
              icon="office"
              iconClassName={styles.icon}
              className={styles.input_icon}
            ></Icon>
          </div>
          <div className={classnames(styles.required, styles.label)}>
            <div className={styles.required_text}>必須</div>
          </div>
          <input
            className={styles.input}
            placeholder="会社名"
            {...register('company', {
              required: '会社名を入力してください。',
            })}
          />
        </div>
        {errors.company && (
          <div className={styles.error}>{errors.company?.message}</div>
        )}

        <div className={styles.relative}>
          {/* include validation with required or other standard HTML validation rules */}
          <div className={styles.icon}>
            <Icon
              icon="department"
              iconClassName={styles.icon}
              className={styles.input_icon}
            ></Icon>
          </div>
          <div className={classnames(styles.elective, styles.label)}>
            <div className={styles.required_text}>任意</div>
          </div>
          <input
            className={styles.input}
            placeholder="部署名"
            {...register('department')}
          />
        </div>

        <div className={styles.check_wrap}>
          <input
            type="checkbox"
            id="kiyaku"
            name="inputNames"
            className={styles.check}
            checked={check}
            onChange={() => {
              setCheck(!check)
            }}
            data-testid="input"
          />
          <div>
            <Link
              to="/terms-of-service"
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              利用規約
            </Link>
            と
            <Link
              to="/privacy-policy"
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              個人情報保護方針
            </Link>
            と
            <Link
              to="/security-policy"
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              セキュリティポリシー
            </Link>
            に<br />同意する
          </div>
        </div>

        <Button type="submit" disabled={!check} className={styles.add_btn}>
          新規登録する
        </Button>
      </form>

      <Button
        size="mini"
        color="secondary"
        className={styles.back_btn}
        onClick={onClickChange}
      >
        戻る
      </Button>
    </>
  )
}

export default HomeSignupInputBlock
