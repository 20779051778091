import { FC, ReactNode } from 'react'
import classnames from 'classnames'
import Footer from 'components/Footer'
import LoadingHeader from './LoadingHeader'

import styles from './index.module.css'

export type loadingLayoutProps = {
  children: ReactNode
  className?: string
  link?: boolean
}

const loadingLayout: FC<loadingLayoutProps> = ({
  children,
  className,
  link = false,
}) => {
  return (
    <div className={styles.body}>
      <LoadingHeader link={link} />
      <div className={classnames(className, styles.main)}>
        <div className={styles.contents}>{children}</div>
      </div>
      <Footer />
    </div>
  )
}

export default loadingLayout
