import { User } from 'models'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import logoImage from "../../assets/images/jfrontier_logo.png";
import classnames from 'classnames'
import styles from './index.module.css'

export type FooterProps = {
  ref?: any
  className?: string
  user?: User
}

const Footer: FC<FooterProps> = ({ ref, className, user }) => {
  return (
    <div ref={ref} className={styles.footer}>
      <div className={classnames(className, styles.wrap)}>
        <div className={classnames(styles.item, styles.logo, className ? styles.home_logo : '')}>
          <a href="https://jfrontier.net/" 
            target="_blank"
            rel="noopener noreferrer">
            <img
              className={classnames(styles.logo_icon, className ? styles.home_logo_icon : '')}
              src={logoImage}
              alt="maintenance"
            />
          </a>
        </div>
        <div className={classnames(styles.item, styles.contact)}>
        {user?.login && (
          <Link to="/contact">
            ご意見・ご要望
          </Link>
        )}
        </div>
        <div className={classnames(styles.item, styles.copyright, className ? styles.home_copyright : '')}>copyright @ 2022 J-Frontier Co.,Ltd.</div>
      </div>
    </div>
  )
}

export default Footer
