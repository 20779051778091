import { FC } from 'react'

export type TableHeaderProps = {
  headers: Array<any>
  onChangeSort: ({
    sortBy,
    isSortEnabled,
  }: {
    sortBy: any
    isSortEnabled: boolean
  }) => void
}

const TableHeader: FC<TableHeaderProps> = ({
  headers = [],
  onChangeSort,
  //onSelectAll,
  //rows,
  //shouldAddCell,
  //sortBy,
  //sortOrder,
}) => {
  // const checked =
  //   rows?.length > 0 && rows.every((row: any) => row._isChecked === true)
  // const shouldDisplayNotChecked =
  //   rows?.some((row: any) => row._isChecked === true) && !checked

  return (
    <thead>
      <tr>
        {headers.map((header: any) => {
          const { isSortEnabled, name, value } = header
          //const shouldDisplaySort = isSortEnabled && sortBy === value
          //console.log('headers', headers)
          // const firstElementThatCanBeSorted = get(
          //   headers.filter((h: any) => h.isSortEnabled),
          //   [0, 'value'],
          //   null,
          // )

          return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <th
              key={value}
              onClick={() => {
                onChangeSort({
                  sortBy: value,
                  //firstElementThatCanBeSorted,
                  isSortEnabled,
                })
              }}
            >
              <p className={isSortEnabled ? 'clickable' : ''}>
                {name}
                {/* {shouldDisplaySort && <Icon icon={sortOrder || 'asc'} />} */}
              </p>
            </th>
          )
        })}
        {/* {shouldAddCell && <th />} */}
      </tr>
    </thead>
  )
}

export default TableHeader
