import { usePut } from 'hooks'
import { useQuery } from 'react-query'
// import { useMutation } from 'react-query'
import { Navigate } from 'react-router-dom'
import axios, { AxiosResponse, AxiosError } from 'axios'
import { useLocation } from 'react-router-dom'

import SnsAuthHeaderBlock from './blocks/SnsAuthHeaderBlock'
import SnsAuthInputBlock from './blocks/SnsAuthInputBlock'

import { Head, Button } from 'components'

const backendUrl = process.env.REACT_APP_API_URL

const fetchSnsUser = (token: string) => {
  return axios
    .get<
      AxiosResponse<{
        data: { jwt: string; user: { confirmed: boolean } }
      }>
    >(`${backendUrl}/auth/google/callback${token}`)
    .then((res: AxiosResponse<any>) => {
      return res.data
    })
    .catch((error: AxiosError<any>) => {
      return error.response
    })
}

const SnsAuth = () => {
  const location = useLocation()
  const token = location.search
  const { data, isLoading, isError, refetch } = useQuery<any>(
    ['users1', token],
    () => fetchSnsUser(token),
    {},
  )
  const {
    mutate: post,
    isLoading: isLoadingP,
    error: errorP,
  } = usePut<{
    confirmed: boolean
  }>({
    url: `${process.env.REACT_APP_API_URL}/users/me`,
    headers: { Authorization: `Bearer ${data?.jwt || null}` },
  })

  console.log('data', data)
  console.log('isError', isError)

  const handleSubmitRegister = async () => {
    const res = await post({
      params: { confirmed: true, username: 'aaaaaaaaaaaaaaaaaaaaaaa' },
    })
    refetch()

    const { jwt } = res
    localStorage.setItem('jwt', jwt)
    console.log('res_post', res)

    return <Navigate to="/" />
  }

  // const { mutate, isLoading, error } = usePost({
  //   url: `${process.env.REACT_APP_API_URL}/users/login`,
  // })

  // const {
  //   mutate: mutateN,
  //   isLoading: isLoadingN,
  //   error: errorN,
  // } = usePost({
  //   url: `${process.env.REACT_APP_API_URL}/users`,
  // })

  if (isLoading || isLoadingP) {
    return <div>loading</div>
  }

  if (isError || errorP) {
    return <div>error</div>
  }

  console.log('data', data)

  if (data?.user?.confirmed === false) {
    return (
      <>
        <Head title="SNS新規登録 | 会議ログβ版" />
        <SnsAuthHeaderBlock />
        SNS新規登録
        <SnsAuthInputBlock />
        <Button onClick={handleSubmitRegister}>登録</Button>
      </>
    )
  }

  return <div>ログインしました</div>
}

export default SnsAuth
